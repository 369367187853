.FencingStats-line {
    padding-left: 20px;
    padding-top: 5px;
}

.FencingStats-zero {
    font-weight: bold;
}

.FencingStats-plus {
    font-weight: bold;
    color: green;
}

.FencingStats-minus {
    font-weight: bold;
    color: #D75B66;
}

.FencingStats-hint {
    color: grey;
    font-size: smaller;
}
