div.OrganizationSelector {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(190,190,190,0.9);
  }

div.OrganizationSelector-selector {
    min-width: 320px;
    max-width: 640px;
    margin: auto;
}

table.OrganizationSelector {
    width: 100%;
}

td.OrganizationSelector {
    text-align: center;
    padding: 20px;
}

div.OrganizationSelector-cell {
    padding: 5px;
    border-radius: 4px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

div.OrganizationSelector-cell:hover {
    color: #FFF695;
    background-color: #23345C;
}

.OrganizationSelector-header {
    color: #23345C;
    background-color: #CCCCCC;
    padding: 20px;
}
