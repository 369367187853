div.BottomBar {
    margin-top: 30px;
    margin-bottom: 5px;
    padding-left: 50px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    background-color: #CCCCCC;
    text-align: center;
}

span.BottomBar-nope {
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;
    background-color: #EDEDED;
}

span.BottomBar {
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.BottomBar:hover {
    color: #FFF695;
    background-color: #23345C;
}
