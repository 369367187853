.TeamSummary-label {
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
}

.TeamSummary-list {
    list-style-type: none;
    padding-left: 50px;
}

.TeamSummary-awesome {
    padding: 5px;
    font-weight: bold;
    width: 60%;
    border-radius: 5px;
    background-color: #23345C;
    color: #EBEBEB;
}

.TeamSummary-good {
    padding: 5px;
    font-weight: bold;
    width: 60%;
    border-radius: 5px;
    background-color: #BFAFA0;
    color: #23345C;
}

.TeamSummary-tie {
    padding: 5px;
    font-weight: bold;
    width: 60%;
    border-radius: 5px;
    background-color: #EBEBEB;
    color: #23345C;
}

.TeamSummary-bad {
    padding: 5px;
    font-weight: bold;
    width: 60%;
    border-radius: 5px;
    background-color: #BD8A44;
    color: #EBEBEB;
}

.TeamSummary-horror {
    padding: 5px;
    font-weight: bold;
    width: 60%;
    border-radius: 5px;
    background-color: #F1BA48;
    color: #23345C;
}
