div.SeriesSummary-label {
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: larger;
}

div.SeriesSummary-headline {
    padding-left: 10px;
    padding-top: 25px;
    padding-bottom: 15px;
    font-weight: bold;
}

div.SeriesSummary-text {
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 15px;
}
