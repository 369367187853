div.CheckoutStep-content {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 10px;
    background-color: #EEE;
}

div.CheckoutStep {
    height: 240px;
}

div.CheckoutStep-header {
    font-weight: bold;
    padding-left: 10px;
    padding-top: 10px;
}

td.CheckoutStep {
    padding: 10px;
    vertical-align: top;
}

td.CheckoutStep-summary {
    padding: 5px;
}

input.CheckoutStep {
    padding: 3px;
    background-color: #FFF695;
    border: none;
    border-radius: 3px;
    color: #23345C;
    height: 15px;
    width: 100%;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
}

input.CheckoutStep-radio {
    appearance: none;
    background-color: #FFF695;
    margin-left: 5px;
    margin-right: 5px;
    font: inherit;
    color: currentColor;
    width: 1em;
    height: 1em;
    border: 0.15em solid #23345C;
    border-radius: 40%;
    transform: translateY(+0.4em);
}

input.CheckoutStep-radio:checked {
    width: 1.2em;
    height: 1.2em;
    background-color: #23345C;
}

ul.CheckoutStep {
    padding: 0;
    list-style-type: square;
}

li.CheckoutStep {
    margin-top: 10px;
}

a.CheckoutStep {
    font-weight: bold;
    text-decoration: underline;
    padding-left: 5px;
    color: #23345C;
}