div.OneSerie {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 5px;
}

div.OneSerie-header {
    padding-left: 5x;
    padding-top: 15px;
    padding-bottom: 5px;
    font-weight: bold;
}

span.OneSerie-ready {
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: chartreuse;
    transition: background-color 0.5s;
}

span.OneSerie-ready:hover {
    color: chartreuse;
    background-color: #23345C;
}

span.OneSerie-downloading {
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #BD8A44;
    transition: background-color 0.5s;
}

span.OneSerie-downloading:hover {
    color: #BD8A44;
    background-color: #23345C;
}

span.OneSerie-withData {
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.OneSerie-withData:hover {
    color: #FFF695;
    background-color: #23345C;
}

span.OneSerie-error {
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #D75B66;
    transition: background-color 0.5s;
}

span.OneSerie-error:hover {
    color: #D75B66;
    background-color: #23345C;
}

ul.OneSerie {
    list-style-type: none;
}

li.OneSerie {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    width: 80%;
    margin: 5px; 
}
