.TabHeader-header {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: larger;
}

.TabHeader-options {
    background-color: #CCCCCC;
    padding-left: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
}
