.FencerSelector {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(190,190,190,0.7);
  }

.FencerSelector-selector {
    min-width: 320px;
    max-width: 640px;
    margin: auto;
    font-size: 11px;
}

.FencerSelector-table {
    width: 100%;
}

.FencerSelector-td {
    text-align: center;
    padding: 4px;
}

span.FencerSelector-text {
    padding: 2px;
    border-radius: 4px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.FencerSelector-text:hover {
    color: #FFF695;
    background-color: #23345C;
}

.FencerSelector-search {
    background-color: #EBEBEB;
    padding: 15px;
    margin: 10px;
    border-radius: 5px;
}

.FencerSelector-header {
    color: #23345C;
    padding: 10px;
}

.FencerSelector-input {
    background-color: #FFF695;
    border: none;
    color: #23345C;
    height: 22px;
    width: 250px;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    border-radius: 5px;
}
