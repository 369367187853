div.SnapshotDisplay-label {
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 5px;
    font-weight: bold;
}

div.SnapshotDisplay-text {
    padding-left: 20px;
    padding-top: 10px;
}

span.SnapshotDisplay-name {
    color: #23345C;
    margin-right: 10px;
    font-weight: bold;
}

span.SnapshotDisplay-text {
    color: #23345C;
    margin-left: 10px;
    margin-right: 30px;
}

ul.SnapshotDisplay {
    list-style-type: none;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 50px;
}

li.SnapshotDisplay {
    background-color: #CCCCCC;
    padding: 5px;
    border-radius: 5px;
}

span.SnapshotDisplay-button {
    margin-left: 10px;
    margin-right: 10px;
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.SnapshotDisplay-button:hover {
    color: #FFF695;
    background-color: #23345C;
}
