button.Direction {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 30px;
    border: 3px;
    border-radius: 3px;
    color: #23345C;
    background-color: #FFF695;

    transition: background-color 0.5s;
}

button.Direction:hover {
    color: #FFF695;
    background-color: #23345C;
}