div.SearchFencer-envelope {
    margin-top: 10px;
    padding-bottom: 20px;
    border-radius: 4px;
    padding: 10px;
    background-color: #EEE;
}

div.SearchFencer-header {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: bold;
}

div.SearchFencer-clean {
    background-color: #CCCCCC;
    padding: 8px;
    border-radius: 5px;
    text-align: center;
}

div.SearchFencer-message {
    background-color: #CCCCCC;
    padding: 8px;
    margin: 10px;
    border-radius: 5px;
    font-weight: bold;
}

input.SearchFencer {
    padding: 3px;
    background-color: #FFF695;
    border: none;
    border-radius: 3px;
    color: #23345C;
    height: 15px;
    width: 50%;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
}

span.SearchFencer-info {
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 20px;
    font-weight: normal;
}

span.SearchFencer-text {
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 4px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.SearchFencer-text:hover {
    color: #FFF695;
    background-color: #23345C;
}

div.SearchFencer-name {
    font-weight: bold;
    padding: 2px;
    margin-top: 20px;
    border-radius: 4px;
    color: #23345C;
    background-color: #EDEDED;
    text-align: center;
}

div.SearchFencer-back {
    border-radius: 4px;
    background-color: #CCCCCC;
    padding: 10px;
    text-align: center;
}

td.SearchFencer {
    text-align: center;
    padding: 4px;
}

table.SearchFencer {
    width: 100%;
}

div.SearchFencer-dropdown {
    padding: 10px;
    margin-top: 20px;
    border-radius: 4px;
    top: 105px;
    left: 20px;
    background-color: #CCCCCC;
}