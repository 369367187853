.Cookies-canvas {
    position: fixed;
    top: 80%;
    left: 0;
    width: 100%;
    height: 20%;
    background-color: rgba(190,190,190,0.7);
}

.Cookies-content {
    min-width: 320px;
    max-width: 640px;
    margin: auto;
    padding: 40px;
    color: #23345C;
    font-size: 15px;
    font-weight: bold;
}

.Cookies-paragraph {
    text-align: center;
}

.Cookies-understand {
    background-color: #FFF695;
    padding: 2px;
    border-radius: 4px;
}
