.TournamentControl-line {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin: 15px;
}

span.TournamentControl {
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.TournamentControl:hover {
    color: #FFF695;
    background-color: #23345C;
}

.TournamentControl-label {
    padding-left: 30px;
    padding-top: 0px;
    padding-bottom: 5px;
    margin: 15px;
}
