div.BoutResult {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    width: 85%;
    margin: 5px;
}

span.BoutResult {
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.BoutResult:hover {
    color: #FFF695;
    background-color: #23345C;
}
