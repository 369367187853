.FinalResults-label {
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
}

.FinalResults-description {
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 20px;
}

.FinalResults-events {
    padding-left: 90px;
}

.FinalResults-text {
    fill: #BBB;
    font-size: 8px;
}

.FinalResults-values {
    fill: #23345C;
    font-size: 8px;
    font-weight: bold;
}
