.TeamMatch-line {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    width: 85%;
    margin: 5px;
}

.TeamMatch-text {
    background-color: #FFF695;
    padding: 3px;
    border-radius: 5px;
}
