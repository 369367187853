div.Highlights-envelope {
    margin-top: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    padding: 10px;
    background-color: #EEE;
}

div.Highlights-header {
    padding-left: 10px;
    padding-top: 10px;
    font-weight: bold;
}

div.Highlights-text {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

span.Highlights {
    margin-left: 10px;
    margin-right: 10px;
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.Highlights:hover {
    color: #FFF695;
    background-color: #23345C;
}
