div.Date {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin: 5px;
}

input.Date {
    padding: 3px;
    margin-right: 10px;
    background-color: #FFF695;
    border: none;
    border-radius: 3px;
    color: #23345C;
    height: 15px;
    width: 30px;
    font-size: 12px;
    font-family: 'Ubuntu', sans-serif;
}

