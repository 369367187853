div.Quality {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin: 5px;
}

input.Quality {
    padding: 3px;
    background-color: #FFF695;
    border: none;
    border-radius: 3px;
    color: #23345C;
    height: 15px;
    width: 200px;
    font-size: 12px;
    font-family: 'Ubuntu', sans-serif;
}

select.Quality {
    padding: 3px;
    background-color: #FFF695;
    color: #23345C;
}