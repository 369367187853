table.FacetSearch {
    background-color: #CCCCCC;
    width: 100%;
    border-spacing: 8px;
    border-radius: 5px;
}

td.FacetSearch-none {
    padding: 5px;
    height: 12px;
}

td.FacetSearch-selected {
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    background-color: #EBEBEB;
    font-weight: bold;
}

td.FacetSearch-option {
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

td.FacetSearch-option:hover {
    color: #FFF695;
    background-color: #23345C;
}

span.FacetSearch:hover {
    color: #FFF695;
    background-color: #23345C;
}
