div.OrganizationPicker {
    display: flex;
    background-color: #CCCCCC;
    border-radius: 5px;
    margin-bottom: 5px;
}

div.OrganizationPicker-children1 {
    flex: 2;
    margin: 8px;
    padding: 5px;
    border-spacing: 8px;
    text-align: center;
}

div.OrganizationPicker-children2 {
    flex: 1;
    margin: 8px;
    padding: 5px;
    border-spacing: 8px;
    text-align: center;
}

div.OrganizationPicker-button {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;

    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
    border: 4px solid #CCCCCC;
}

div.OrganizationPicker-button:hover {
    color: #FFF695;
    background-color: #23345C;
}
