text.PointChart-text {
    fill: #23345C;
    font-size: 16px;
}
rect.PointChart-background {
    fill: #EBEBEB;
}

circle.PointChart-point {
    stroke-width: 0px;
    stroke: none;
    fill: #BFAFA0;
}

line.PointChart {
    stroke: #23345C;
}

line.PointChart-analysis {
    stroke-width: 4px;
    stroke: #D75B66;
}
