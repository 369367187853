div.Series {
    margin-top: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    padding: 10px;
    background-color: #EEE;
}

div.Series-clean {
    background-color: #CCCCCC;
    padding: 8px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
}

div.Series-header {
    padding: 10px;
    font-weight: bold;
}

span.Series-info {
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 20px;
    font-weight: normal;
}

span.Series-text {
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 4px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.Series-text:hover {
    color: #FFF695;
    background-color: #23345C;
}
