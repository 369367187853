div.SelectedTournament-header {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
}

div.SelectedTournament-footer {
    background-color: #CCCCCC;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
}
