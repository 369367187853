button.EssentialButton {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;

    font-size: 14px;
    font-family: 'Ubuntu', sans-serif;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
    border: 4px solid #CCCCCC;
}

button.EssentialButton:hover {
    color: #FFF695;
    background-color: #23345C;
}

button.EssentialButton-selected {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 5px;

    font-size: 14px;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
    color: #23345C;
    background-color: #EBEBEB;
    border: 4px solid #CCCCCC;
}
