@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Ubuntu&display=swap');

.App {
  min-width: 320px;
  max-width: 640px;
  background-color: #FFFFFF;
  margin: auto;

  font-size: 14px;
  font-family: 'Ubuntu', sans-serif;
}

.App-hr {
  border-top: 8px solid #BFAFA0;
  border-radius: 5px;
  margin-top: 60px;
}