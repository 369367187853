circle.point {
    stroke: none;
    fill: #BFAFA0;
}

circle.hotPoint {
    stroke: none;
    fill: #D75B66;
}

circle.leftPoint {
    stroke: none;
    fill: #fb4d5c;
}

circle.rightPoint {
    stroke: none;
    fill: chartreuse;
}

.textLeft {
    fill: #23345C;
    font-size: 22px;
    text-anchor: start;
}

.textRight {
    fill: #23345C;
    font-size: 22px;
    text-anchor: end;
}

.textMiddle {
    fill: #BD8A44;
    font-size: 30px;
    text-anchor: middle;
}
