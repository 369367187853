div.Checkout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(190,190,190,0.9);
  }

div.Checkout-selector {
    min-width: 320px;
    max-width: 640px;
    margin: auto;
    background-color: #FFF;
    padding-bottom: 10px;
}

div.Checkout-content {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 10px;
    background-color: #EEE;
}

div.Checkout-header {
    padding: 10px;
    font-weight: bold;
}
