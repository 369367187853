div.TournamentMgmt {
    background-color: #CCCCCC;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin: 5px;
}

div.TournamentMgmt-additional {
    margin-top: 10px;
    margin-bottom: 10px;
}

div.TournamentMgmt-strong {
    font-weight: bolder;
}

input.TournamentMgmt {
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #FFF695;
    border: none;
    border-radius: 3px;
    color: #23345C;
    height: 15px;
    font-size: 12px;
    font-family: 'Ubuntu', sans-serif;
}

input.TournamentMgmt-short {
    width: 40px;
}

input.TournamentMgmt-long {
    width: 150px;
}

select.TournamentMgmt {
    padding: 3px;
    background-color: #FFF695;
    color: #23345C;
}