div.LanguageSelector {
    padding: 3px;
    background-color: #BFAFA0;
    border-radius: 5px;
    text-align: right;
    font-weight: bold;
    color: #FFFFFF;
}

span.LanguageSelector {
    font-weight: normal;
    margin-left: 10px;
    margin-right: 10px;
}

span.LanguageSelector-selected {
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
}
