.Footer {
    display: flex;
    justify-content: space-evenly;
}

.Footer-item {
    width: 100px;
    height: 50px;
    padding: 15px;
  }
