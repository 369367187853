div.Opponent-header {
    padding-left: 10px;
    padding-top: 30px;
}

div.Opponent-message {
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 20px;
}

div.Opponent-match {
    padding-left: 10px;
    padding-top: 10px;
    font-weight: bold;
}

ul.Opponent {
    list-style: none;
}

li.Opponent {
    padding: 10px;
}

div.Opponent-ctw {
    padding-left: 50px;
    font-size: large;
    font-weight: bold;
}
