div.Translations {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

div.Translations-section {
    margin: 10px;
    padding: 10px;
    background-color: #eee;
}

input.Translations {
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #FFF695;
    border: none;
    border-radius: 3px;
    color: #23345C;
    height: 15px;
    font-size: 12px;
    font-family: 'Ubuntu', sans-serif;
}

input.Translations-short {
    width: 80px;
}

input.Translations-long {
    width: 160px;
}

button.Translations {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 30px;
    border: 3px;
    border-radius: 3px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

button.Translations:hover {
    color: #FFF695;
    background-color: #23345C;
}
