.Vix-label {
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
}

.Vix-description {
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 20px;
}
