div.SerieMgmt {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin: 5px;
}

select.SerieMgmt {
    padding: 3px;
    background-color: #FFF695;
    color: #23345C;
}