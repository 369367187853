text.BarChart-text {
    fill: #23345C;
    font-size: 16px;
}

rect.BarChart-box1 {
    stroke: transparent;
    stroke-width: 0px;
    fill: #F1BA48;
}

rect.BarChart-box2 {
    stroke: transparent;
    stroke-width: 0px;
    fill: #BFAFA0;
}

rect.BarChart-background {
    fill: #EBEBEB;
}

circle.BarChart-point {
    stroke: transparent;
    stroke-width: 0px;
    fill: #D75B66;
}

line.BarChart {
    stroke: #23345C;
}
