.FencerMatches-list {
    list-style-type: none;
    padding-left: 50px;
}

.FencerMatches-item {
    padding: 5px;
}

.FencerMatches-header {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 5px;
}
