.FencerDisplay-label {
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 5px;
    font-weight: bold;
}

.FencerDisplay-headline {
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: larger;
}

.FencerDisplay-line {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin: 15px;
}

span.FencerDisplay {
    margin-left: 10px;
    margin-right: 10px;
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

span.FencerDisplay:hover {
    color: #FFF695;
    background-color: #23345C;
}

span.FencerDisplay-selected {
    margin-left: 10px;
    margin-right: 10px;
    padding: 3px;
    border-radius: 5px;
    color: #23345C;
    background-color: #EBEBEB;
    font-weight: bold;
}

.FencerDisplay-text {
    padding-left: 20px;
    padding-top: 10px;
}
