rect.box1 {
    stroke: transparent;
    stroke-width: 0px;
    fill: #23345C;
}

rect.box2 {
    stroke: transparent;
    stroke-width: 0px;
    fill: #BFAFA0;
}

rect.box3 {
    stroke: transparent;
    stroke-width: 0px;
    fill: #BD8A44;
}

rect.box4 {
    stroke: transparent;
    stroke-width: 0px;
    fill: #F1BA48;
}

.darkText {
    fill: #23345C;
    font-size: 22px;
}

.lightText {
    fill: #EBEBEB;
    font-size: 22px;
    font-weight: bold;
}
