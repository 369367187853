div.Logo {
    padding-left: 15px;
    padding-top: 5px;
    display: flex;
}

div.Logo-img {
    flex: 1;
}

h1.Logo {
    flex: 2;
    padding: 10px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
}
