text.SimpleBarChart {
    fill: #23345C;
    font-size: 12px;
}

rect.SimpleBarChart-background {
    fill: #EBEBEB;
}

rect.SimpleBarChart {
    fill: #BFAFA0;
    stroke: #23345C;
    stroke-width: 2px;
}
