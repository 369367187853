div.RainbowMgmt {
    background-color: #CCCCCC;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin: 5px;
}

input.RainbowMgmt {
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #FFF695;
    border: none;
    border-radius: 3px;
    color: #23345C;
    height: 15px;
    width: 40px;
    font-size: 12px;
    font-family: 'Ubuntu', sans-serif;
}

button.RainbowMgmt {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 30px;
    border: 3px;
    border-radius: 3px;
    color: #23345C;
    background-color: #FFF695;
    transition: background-color 0.5s;
}

button.RainbowMgmt:hover {
    color: #FFF695;
    background-color: #23345C;
}