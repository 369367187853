span.TableItem-selectedTab {
    background-color: #FFF695;
    color: #D75B66;
    padding: 10px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 5px;
}

span.TableItem-ordinaryTab {
    background-color: #FFF695;
    padding: 10px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 5px;
}