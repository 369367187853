.TournamentGraph-label {
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
}

.TournamentGraph-text {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
