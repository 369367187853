div.EventState {
    background-color: #CCCCCC;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin: 5px;
}

select.EventState {
    padding: 3px;
    background-color: #FFF695;
    color: #23345C;
}

input.EventState {
    padding: 3px;
    background-color: #FFF695;
    color: #23345C;
    height: 15px;
}
