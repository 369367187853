div.ProgressBar {
    width: 100%;
    text-align: center;
}

span.ProgressBar {
    margin-left: 5px;
    margin-right: 5px;
}

span.ProgressBar-grey {
    color: #CCC;
    margin-left: 5px;
    margin-right: 5px;
}
