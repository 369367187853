.ContactUs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(240,240,240,0.95);
    font-size: 12px;
  }

.ContactUs-space {
    height: 100px;
}

.ContactUs-head {
    padding: 15px;
    font-weight: bold;
}

.ContactUs-content {
    padding: 15px;
}

.ContactUs-selector {
    min-width: 320px;
    max-width: 640px;
    margin: auto;
}
